import { render, staticRenderFns } from "./PostsPageShow.vue?vue&type=template&id=93b51180&scoped=true&"
import script from "./PostsPageShow.vue?vue&type=script&lang=js&"
export * from "./PostsPageShow.vue?vue&type=script&lang=js&"
import style0 from "./PostsPageShow.vue?vue&type=style&index=0&id=93b51180&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b51180",
  null
  
)

export default component.exports