import { mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import { htmlDecode } from '@vue-storefront/core/filters'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'
import config from 'config'

export default {
  components: {
    Breadcrumbs
  },
  serverPrefetch () {
    return this.fetchPost()
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      post: 'wordpress/currentPost',
      currentCategory: 'wordpress/currentCategory'
    }),
    futeredPosts () {
      if (!this.currentCategory || !this.currentCategory.posts || !this.currentCategory.posts.nodes) return []
      return this.currentCategory.posts.nodes
    },
    relatedPosts () {
      return null
    }
  },
  filters: {
    prettyDate: function (date) {
      let newDate = new Date(date)
      let dateFormat = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
      return newDate.toLocaleDateString('pl-PL', dateFormat)
    }
  },
  mounted () {
    this.fetchPost()
  },
  watch: {
    '$route' (to, from) {
      this.fetchPost()
    }
  },
  metaInfo () {
    let titleTemplate = '%s'
    let title
    const meta = []
    if (this.post && this.post.title) {
      title = this.post.title
      meta.push({
        vmid: 'og:title',
        name: 'og:title',
        property: 'og:title',
        content: this.post.title
      })
      meta.push({
        vmid: 'og:image',
        name: 'og:image',
        property: 'og:image',
        content: this.post.featuredImage.node.sourceUrl
      })
      meta.push({
        vmid: 'og:description',
        name: 'og:description',
        property: 'og:description',
        content: htmlDecode(this.post.content).replace(/<[^>]+>/g, ' ').replace(/\s{2,10}/g, ' ').slice(0, 150)
      })
      meta.push({
        vmid: 'description',
        name: 'description',
        content: htmlDecode(this.post.content).replace(/<[^>]+>/g, ' ').replace(/\s{2,10}/g, ' ').slice(0, 150)
      })
      meta.push({
        vmid: 'og:url',
        name: 'og:url',
        content: this.post ? htmlDecode(config.server.hostName + this.$route.path) : ''
      })
    }
    return {
      titleTemplate,
      title,
      meta
    }
  },
  methods: {
    fetchPost () {
      this.loading = true
      return this.$store.dispatch('wordpress/loadPost', { slug: this.$route.params.slug }).then(() => { this.loading = false }).catch(() => { this.loading = false })
    },
    getPostImage (post) {
      if (post.featuredImage != null && post.featuredImage.node.sourceUrl != null) {
        return {
          'backgroundImage': `url(${post.featuredImage.node.sourceUrl})`
        }
      } else {
        return {
          'backgroundColor': `#ccc`
        }
      }
    },
    sanitizeHtml: sanitizeHtml
  }
}
