<template>
  <div class="blog-page blog-page-posts-show">
    <div v-if="loading">
      <div class="progress">
        <div class="indeterminate" />
      </div>
      <div class="blog-page--hero">
        <h1 class="blog-page--page-title bg-cl-secondary brdr-radius-20 h-44 mb5" />
        <h1 class="blog-page--page-title bg-cl-secondary brdr-radius-20 h-44 mw-550" />
        <h3 class="blog-page--page-title blog-page--preview-date bg-cl-secondary brdr-radius-20 h-16 mw-200" />
        <div class="container">
          <div class="blog-page--preview-large bg-cl-secondary brdr-radius-20" />
        </div>
        <div class="container bg-cl-secondary brdr-radius-20 mt20 h-16" />
        <div class="container bg-cl-secondary brdr-radius-20 mt20 h-16" />
      </div>
    </div>
    <div v-else-if="post">
      <div class="container">
        <breadcrumbs
          :routes="[{name: 'Home', route_link: '/'},{name: 'Porady', route_link: '/porady'}]"
          :active-route="post.title"
        />
      </div>
      <!-- Article rich snippet -->
      <div class="hidden" itemscope itemtype="https://schema.org/Article">
        <span itemprop="author" :content="post.author.node.name" />
        <span itemprop="datePublished">{{ post.date }}</span>
        <span itemprop="dateModified">{{ post.modified }}</span>
        <span itemprop="headline" v-html="post.title" />
        <span itemprop="image" :content="post.featuredImage.node.sourceUrl" />
        <span itemprop="publisher" itemtype="http://schema.org/Organization" itemscope>
          <span itemprop="name" content="Agrosimex.pl" />
          <span itemprop="logo" itemscope itemtype="https://schema.org/ImageObject">
            <img :src="hostName + '/assets/android-icon-72x72.png'" alt="agrosimex logo" itemprop="contentUrl">
            <span itemprop="url" :content="hostName" />
          </span>
        </span>
      </div>
      <!-- rich snippet end -->
      <div class="blog-page--hero">
        <h1 class="blog-page--page-title" v-html="post.title" />
        <h3 class="blog-page--page-date blog-page--preview-date">
          {{ post.date | prettyDate }}
        </h3>
      </div>
      <div class="container">
        <div class="blog-page--preview-large blog-page--preview-large-hero-merge" :style="getPostImage(post)" />
      </div>
      <div class="container mt40">
        <div class="row center-xs">
          <div class="col-xs-12 col-sm-8 col-lg-8 start-xs" v-html="post.content" />
          <div v-if="futeredPosts" class="col-xs-12 hidden-xs col-sm-4 col-lg-offset-1 col-lg-3 p0">
            <div class="bg-cl-white p15">
              <p class="cl-alternative mt0 mb10 weight-600 h5">
                Przeczytaj także:
              </p>
              <div>
                <div class="mt10" v-for="futeredPost of futeredPosts" :key="futeredPost.id">
                  <router-link :to="localizedRoute('/porady') + '/' + futeredPost.slug">
                    <img :src="futeredPost.featuredImage.node.medium" :alt="futeredPost.title" width="440" height="312" class="blog-image">
                    <div class="mb15 start-xs">
                      <p class="weight-500 h6">
                        {{ futeredPost.title }}
                      </p>
                      <span class="h6 cl-dark-blue">
                        {{ $t('Read more') }} >
                      </span>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row center-xs">
          <div class="col-xs-12 col-lg-12 start-xs">
            <router-link :to="localizedRoute(`/porady`)" class="link blog-page--preview-link">
              {{ $t('Back to posts') }}
            </router-link>
          </div>
        </div>
        <no-ssr>
          <div v-if="relatedProducts && category" class="row blog-page--products-container mt40 center-xs">
            <div class="col-xs-12 col-lg-12 p0">
              <h3 class="blog-page--products-title">
                Popularne {{ category.h1_text }}
              </h3>
              <product-listing :columns="3" :mobile-columns="6" :products="relatedProducts" :is-not-show-hover="true" />
              <div class="center-xs">
                <router-link :to="categoryLink(category)" class="link blog-page--category-link col-xs-12">
                  {{ $t('Zobacz więcej') }}
                </router-link>
              </div>
            </div>
          </div>
        </no-ssr>
      </div>
    </div>
    <div v-else>
      <h2>
        {{ $t('No post found.') }}
      </h2>
    </div>
  </div>
</template>

<script>
import Show from 'src/modules/vsf-wordpress/components/posts/Show.js'
import config from 'config'
import ProductListing from 'theme/components/core/ProductListing.vue'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import NoSSR from 'vue-no-ssr';
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related'

export default {
  mixins: [Show],
  data () {
    return {
      relatedProducts: [],
      category: null
    }
  },
  components: {
    'no-ssr': NoSSR,
    ProductListing
  },
  name: 'PostsShow',
  computed: {
    hostName () {
      return config.server.hostName
    },
    getCategoryId () {
      let tags = this.post.tags && this.post.tags.nodes
      return tags.length && tags[0]
        ? tags[0].name
        : null
    }
  },
  methods: {
    async fetchPost () {
      this.loading = true
      await this.$store.dispatch('wordpress/loadPost', { slug: this.$route.params.slug })
      this.loading = false
      const id = this.post.categories.nodes[0].id
      this.$store.dispatch('wordpress/loadCategoryById', { id })
      if (!this.getCategoryId) return
      this.refreshList()
      this.category = this.$store.state.category.list.find(c => { return c.id === Number(this.getCategoryId) })
    },
    refreshList () {
      let sku = [this.getCategoryId]
      let key = 'category_ids'

      let relatedProductsQuery = prepareRelatedQuery(key, sku)
      this.$store.dispatch('product/list', {
        query: relatedProductsQuery,
        size: 8,
        prefetchGroupProducts: false,
        updateState: false
      }).then((response) => {
        this.relatedProducts = response.items
          ? response.items
          : []
      })
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>

<style>
.blog-page-posts-show img {
  max-width: 100%;
  height: auto;
}
</style>
<style lang="scss" scoped>
@import 'src/modules/vsf-wordpress/components/blog/scss/blog';

.blog-page-posts-show {
  background: $white-smoke;
  .blog-page--hero {
    background: #F5F5F5;
    padding-top: 100px;
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-top: 0px;
    }
  }

  .blog-page--page-title {
    display: block;
    text-align: center;
    color: $nearly-black;
    margin: 0 auto 30px;
    max-width: 650px;
  }

  .blog-page--page-date.blog-page--page-date {
    display: block;
    text-align: center;
    color: $nearly-black;
    font-size: 16px;
    margin: 20px auto 10px;
    font-weight: 400;
  }

  .blog-page--preview-large {
    margin-bottom: 0;
    &:before {
      display: none;
    }
  }

  .blog-page--post-container {
    padding: 20px;
    background-color: $white-smoke;
    h3 {
      color: $nearly-black;
      margin: 20px 0;
    }
    p {
      font-family: $PTSans;
      margin: 0 0 40px;
      font-size: 19px;
      line-height: 1.65;
      color: #444;

    }
    p + h3 {
      margin-top: 20px * 2;
    }
    img {
      box-shadow: 0 2px 10px 1px rgba(65, 64, 64, 0.2);
      border-radius: 5px;
      margin: 20px 0 30px;
    }
  }
  .blog-page--entries {
    padding: 60px 0 20px;
    background: #f9f9f9;
    h2 {
      margin-top: 0;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
  .h-44 {
    height: 44px;
  }
  .h-16 {
    height: 16px;
  }
  .mw-550 {
    max-width: 550px !important;
  }
  .mw-200 {
    max-width: 200px !important;
  }
/* Progress Bar */
.progress {
  position: relative;
  height: 4px;
  display: block;
  width: 100%;
  background-color: #18357b;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 5px 0 0 0;
  overflow: hidden;
}
  .progress .determinate {
    position: absolute;
    background-color: inherit;
    top: 0;
    bottom: 0;
    background-color: #041D59;
    transition: width .3s linear;
  }
  .progress .indeterminate {
    background-color: #041D59;
  }
    .progress .indeterminate:before {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
              animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  }
  .progress .indeterminate:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
              animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
      -webkit-animation-delay: 1.15s;
              animation-delay: 1.15s;
  }

  @-webkit-keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%; }
    60% {
      left: 100%;
      right: -90%; }
    100% {
      left: 100%;
      right: -90%; } }
  @keyframes indeterminate {
    0% {
      left: -35%;
      right: 100%; }
    60% {
      left: 100%;
      right: -90%; }
    100% {
      left: 100%;
      right: -90%; } }
  @-webkit-keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%; }
    60% {
      left: 107%;
      right: -8%; }
    100% {
      left: 107%;
      right: -8%; } }
  @keyframes indeterminate-short {
    0% {
      left: -200%;
      right: 100%; }
    60% {
      left: 107%;
      right: -8%; }
    100% {
      left: 107%;
      right: -8%;
    }
  }
  .blog-page--products-title {
    color: #041D59 !important;
     @media (max-width: 767px) {
       font-size: 20px !important;
       padding-left: 5px;
       padding-right: 5px;
     }
  }
 .blog-page .blog-page--category-link {
    margin: 20px 0 40px;
    color: #ffffff;
    display: inline-block;
    background-color: #ff8100;
    text-decoration: none;
    font-family: "protipo", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    width: auto;
    padding: 10px 5%;
 }
 .blog-page--products-container {
   padding: 0 !important;
 }
</style>
